//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import VueRecaptcha from "vue-recaptcha";
import BaseLayout from "@/layouts/Base";
import { account_service } from "@/services";
export default {
  name: "forgot-password-page",
  components: {
    BaseLayout,
    VueRecaptcha,
  },
  metaInfo: {
    title: "Forgot Password",
  },
  data() {
    return {
      error_msg: undefined,
      reset_data: {
        email: null,
        recaptcha: null,
      },
      reset_error: {
        email: null,
        recaptcha: null,
      },
    };
  },
  methods: {
    clear_errors() {
      this.error_msg = "";
      this.reset_error.email = "";
      this.reset_error.recaptcha = "";
    },
    submit() {
      this.clear_errors();
      this.$refs.submit_form.validate().then((result) => {
        if (result) {
          account_service
            .post(this.reset_data, "forgot")
            .then((data) => {
              console.log(data);
              this.$q.notify({
                icon: "mdi-check",
                duration: 5000,
                color: "positive",
                textColor: "white",
                message:
                  "If your account exists with this email address, you will recieve a link to reset your password.",
              });
              this.$router.push({ name: "login", query: { next: "home" } });
            })
            .catch((error) => {
              // clear recapcha, server verification will error with duplicate if not cleared
              this.$refs.recaptcha.reset();
              this.reset_data.recaptcha = "";
              if (error.response && error.response.data)
                if (
                  this.check_fields_for_error(
                    error.response.data,
                    this.reset_error,
                    ["email", "recaptcha"]
                  )
                ) {
                  this.error_msg = "Please fix the error(s) above to continue";
                } else {
                  this.error_msg = this.decode_error_message(error);
                }
            });
        }
      });
    },
    verify_recaptcha(response) {
      if (response) {
        this.reset_data.recaptcha = response;
      }
    },
  },
};
